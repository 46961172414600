<template>
  <div class="general-data">
    <v-form v-model="valid" ref="form">
      <div class="tway-header-2 mb-4">Propuesta de valor</div>
      <v-textarea
        v-model="formData.valueProposal"
        :rules="[required('Propuesta de valor'), validateMaxLength(250)]"
        counter
        :counter-value="formatCounter(255)"
        class="mb-3 roboto"
        name="input-7-1"
        placeholder="Escribe una breve descripción de la propuesta de valor"
        outlined
        no-resize
      />
      <div class="tway-header-2 mb-4">
        Entregables
        <v-tooltip bottom max-width="20em">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >Descripción sobre los productos, servicios solución a la medida responderás a la necesidad levantada por el
            Cliente</span
          >
        </v-tooltip>
      </div>
      <v-textarea
        v-model="formData.deliverables"
        :rules="[required('Entregables'), validateMaxLength(250)]"
        counter
        :counter-value="formatCounter(255)"
        class="mb-3 roboto"
        name="input-7-1"
        placeholder="Escribe una breve descripción"
        outlined
        no-resize
      />
      <div class="tway-header-2 mb-4">Presupuesto <small>(UF)</small></div>
      <v-text-field
        v-model="formData.budget"
        :rules="[required('Presupuesto'), validateMaxNumber(100000000000000)]"
        class="mb-4 roboto"
        type="number"
        min="0"
        max="100000000000000"
        placeholder="Escribe un monto estimado"
        outlined
      />
      <div class="tway-header-2 mb-4">
        Oferta Económica
        <v-tooltip bottom max-width="20em">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span>Archivo con presupuesto detallado</span>
        </v-tooltip>
      </div>
      <v-file-input
        v-model="formData.economicalOffer"
        append-icon="mdi-paperclip"
        :prepend-icon="null"
        :rules="[() => (formData.economicalOffer ? true : 'Campo Requisitos técnicos es requerido')]"
        accept="application/pdf"
        class="mb-10 roboto"
        outlined
        color="rgb(150, 82, 223)"
        placeholder="Agrega tu archivo aquí"
        :value="formData.technicalRequirements"
        @change="uploadEconomicalOffer"
      />
      <div class="tway-header-2 mb-4">
        Duración de Proyecto
        <v-tooltip bottom max-width="20em">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span>Indica una fecha de inicio y de término</span>
        </v-tooltip>
      </div>
      <v-row>
        <v-col md="6" class="pr-md-12">
          <v-menu
            v-model="showStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(formData.period.startDate)"
                color="tway-violet"
                label="Fecha de Inicio"
                :rules="[(v) => !!v || 'Campo Fecha de Inicio es requerido']"
                placeholder="dd-mm-aaaa"
                outlined
                autocomplete="off"
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                required
              />
            </template>
            <v-date-picker
              color="tway-violet"
              :first-day-of-week="1"
              @input="showStartDate = false"
              v-model="formData.period.startDate"
              :min="new Date().toISOString()"
            />
          </v-menu>
        </v-col>
        <v-col md="6" class="pl-md-12">
          <v-menu
            v-model="showEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(formData.period.endDate)"
                color="tway-violet"
                label="Fecha de Término"
                :rules="[(v) => !!v || 'Campo Fecha de Término es requerido']"
                placeholder="dd-mm-aaaa"
                outlined
                autocomplete="off"
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                required
              />
            </template>
            <v-date-picker
              color="tway-violet"
              :first-day-of-week="1"
              @input="showEndDate = false"
              :min="formData.period.startDate"
              v-model="formData.period.endDate"
            />
          </v-menu>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ValidatorsService from "@/services/validatorsService";

export default {
  mounted() {
    if (this.stepIsComplete(this.stepIndex)) {
      let stepData = this.getStep(this.stepIndex);
      this.formData = stepData.data;
    } else {
      this.setInProgress(this.stepIndex);
    }
  },
  updated() {
    if (this.valid) {
      this.setComplete(this.stepIndex);
      this.setStepData({
        index: this.stepIndex,
        data: { ...this.formData },
      });
    } else {
      this.setStepData({
        index: this.stepIndex,
        data: { ...this.formData },
      });
      this.setInProgress(this.stepIndex);
    }
  },
  name: "GeneralData",
  data() {
    return {
      stepIndex: 0,
      valid: false,

      showStartDate: false,
      showEndDate: false,
      formData: {
        valueProposal: "",
        deliverables: "",
        budget: "",
        economicalOffer: null,
        period: {
          startDate: null,
          endDate: null,
        },
      },
    };
  },
  methods: {
    ...mapActions("applyForProject", ["setInProgress", "setComplete", "setIncomplete", "setStepData", "resetStep"]),
    formatCounter: (max) => (text) => max - text.length,
    required: (propertyType) => ValidatorsService.required(propertyType),
    validateMaxLength: (max) => ValidatorsService.validateMaxLength(max),
    validateMaxNumber: (number) => ValidatorsService.validateMaxNumber(number),
    uploadEconomicalOffer(file) {
      this.formData.economicalOffer = file;
    },
    formatDate(date) {
      if (date) {
        let splited = date.split("-");
        return `${splited[2]}-${splited[1]}-${splited[0]}`;
      }
      return null;
    },
  },
  computed: {
    ...mapGetters({
      stepIsComplete: "applyForProject/getStepIsComplete",
      getStep: "applyForProject/getStep",
    }),
  },
};
</script>

<style scoped>
.input-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
}
.v-application .primary--text {
  caret-color: rgb(150, 82, 223);
  color: rgb(150, 82, 223) !important;
}
.general-data .v-application .primary--text {
  caret-color: rgb(150, 82, 223);
  color: rgb(150, 82, 223) !important;
}

.general-data >>> .v-file-input .v-input__icon {
  transform: rotate(-30deg) !important;
}
</style>
